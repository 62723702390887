<style lang="scss" scoped>
  .header-warp{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    margin: 0;
    font-size: 15px;
    .logo{
      height: 36px;
      width: 130px;
      position: relative;
      img{
        height: 100%;
      }
    }
    .menu{
      display: flex;
      color: #FFF;
      li{
        padding: 15px 25px;
        cursor: pointer;
        position: relative;
      }
      .menuitem{
        margin-right: 15px;
        position: relative;
        >li>a{
          color: #FFF;
        }
        .submenu{
          display: none;
          position: absolute;
          left: 50%;
          margin-left: -63px;
          background: #FFF;
          color: #000;
          border-radius: 8px;
          overflow: hidden;
          width: 126px;
          text-align: center;
          li:hover{
            background: #eee;
          }
        }
        &:hover .submenu{
          display: block;
        }
      }
    }
		.menu-black {
			color: $--door-primary;
			.menuitem {
				>li>a {
					color: $--door-primary;
				}
				.submenu {
					background-color: rgba($--door-primary,.1);
          li:hover{
            background: rgba($--door-primary,.5);
						a {
							color: #FFFFFF;
						}
          }
				}
			}
		}
  }
</style>
<template>
  <div class="header-warp">
    <div class="logo">
      <img src="~@/assets/images/logo-292-80.png" alt="" v-if="[1,2,3].includes(top)"/>
      <img src="~@/assets/images/logo-292-80-white.png" alt="" v-else/>
    </div>
    <div class="menu" :class="{'menu-black':[1,2,3].includes(top)}">
      <ul class="menuitem">
        <li><router-link to="/">{{$t('live.header.home')}}</router-link></li>
      </ul>
      <ul class="menuitem">
        <li>{{$t('live.header.liveNow')}}</li>
        <ul class="submenu">
          <li><router-link to="/upload">{{$t('live.header.download')}}</router-link></li>
          <li><a :href = "$liveplatformBasePath">{{$t('live.header.console')}}</a></li>
        </ul>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    top: {
      type: Number,
      default: 0
    }
  },
  data() {
    return{
    }
  },
  name:'broadcast',
  methods: {
    
  },
};
</script>